import { ResourceShape } from '@whop/resources/types';
import { AppResourceContext } from './appLevelResources';
import { definePlainResource } from '@whop/resources/plain';
import { createFlatResourceCache } from '@whop/resources/utils';

export function definePlainDerivedResource<TDerivedModel, TFromModel>(options: {
  derivesFrom: ResourceShape;
  mapTo: (from: TFromModel) => TDerivedModel;
  key: string;
  resourceContext: AppResourceContext;
  fallback: TDerivedModel;
}) {
  const { key, resourceContext, fallback, mapTo, derivesFrom } = options;

  const plainCache = resourceContext.__appCache.getOrCreateCache<AnyInternalOnly>(key);
  const cache = createFlatResourceCache(plainCache, key);

  return definePlainResource({
    cache,
    name: key,
    dependencies: [derivesFrom],
    fallbackValue: fallback,
    resolve: async () => {
      const originalResource = await derivesFrom.getOrFetch();
      if (!originalResource) {
        return fallback;
      }
      return mapTo(originalResource);
    },
    options: {
      __manageResolve: resourceContext.__manageResolve,
      __isDebug: resourceContext.__isDebug,
    },
  });
}
