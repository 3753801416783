import { AppContainer } from '../app.types/state';
import { FavoritesContainer } from '../app.containers/FavoritesContainer';
import { createConfigContainer } from './createConfigContainer';

export async function createFavoritesContainer(self: AppContainer): Promise<FavoritesContainer> {
  return new FavoritesContainer({
    ...self.context,
    configContainer: await self.resolveOnce(createConfigContainer),
  });
}
