import React from 'react';
import { useInternalContext } from './context';
import { AsyncRegistryShape } from './types';

export function useRegisteredComponent<TName extends keyof AsyncRegistryShape>(name: TName) {
  return useRegisteredComponentFn()(name);
}

function useRegisteredComponentFn() {
  const registry = useInternalContext();
  return <TName extends keyof AsyncRegistryShape>(name: TName) => {
    const Component = registry[name];
    const isReady = Component.isReady();
    if (!isReady) {
      console.error(
        `error(registry): Using non-preloaded component: \`${name}\`. This is noop for SSR.`
      );
    }
    return Component;
  };
}

useRegisteredComponent.fn = useRegisteredComponentFn;

type AsyncRegistryProps<TName extends keyof AsyncRegistryShape> = {
  use: TName;
} & React.ComponentProps<AsyncRegistryShape[TName]>;

export function AsyncRegistry<TName extends keyof AsyncRegistryShape>(
  props: AsyncRegistryProps<TName>
) {
  const { use, ...pass } = props;
  const Component = useRegisteredComponent(use);
  // @ts-ignore
  return <Component {...pass} />;
}
