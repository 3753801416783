import { AppContainer } from '../app.types/state';
import { MultiInsertContainer } from '../app.containers/MultiInsertContainer';
import { createCartContainer } from './createCartContainer';

export async function createMultiInsertContainer(
  self: AppContainer
): Promise<MultiInsertContainer> {
  return new MultiInsertContainer({
    cartContainer: await self.resolveOnce(createCartContainer),
    ...self.context,
  });
}
