import parseLinkHeader from 'parse-link-header';

type PaginationModel = {
  next: string;
  last: string;
};

function readPagination(link: string): PaginationModel {
  const parsed = parseLinkHeader(link);
  if (!parsed) {
    return { next: '', last: '' };
  }

  return {
    next: parsed?.next?.url || '',
    last: parsed?.last?.url || '',
  };
}

// Note: exported only for tests
export const __readPagination = readPagination;

export function getPaginationState(response: Response): PaginationModel {
  const link = response.headers.get('link') || '';
  return readPagination(link);
}
