import { Container } from '@whys/app/lib/state';
import { AppResourceContext } from '../app.types/state';
import { RuntimeConfig } from '../app.types/app';
import { defineSelector } from '../tmp.prototyping/selector';

type PaginableResourceNames = keyof RuntimeConfig['pagination']['pageSizePerResource'];

type LocalState = void;

type LocalProps = {
  resourceContext: AppResourceContext;
};

export class ConfigContainer extends Container<LocalState> {
  private state: LocalState;
  private config: RuntimeConfig;

  select = defineSelector({
    pagination: () => this.config.pagination,
    price: () => this.config.price,
  });

  constructor(private props: LocalProps) {
    super();

    const { resourceContext } = props;

    this.config = resourceContext.__runtimeConfig;
  }

  selectInitialPageSize(resource: PaginableResourceNames): number {
    const { pageSize, pageSizePerResource } = this.config.pagination;
    return pageSizePerResource[resource] || pageSize;
  }
}

export type ConfigContainerType = ConfigContainer;
