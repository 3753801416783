import { DeepPartial } from './types';

function isObject(value: unknown): value is object {
  return value !== null && typeof value === 'object';
}

export function deepMerge(...sources: object[]): object {
  let returnValue = {};
  for (const source of sources) {
    if (Array.isArray(source)) {
      if (Array.isArray(returnValue)) {
        returnValue = [...returnValue, ...source];
      } else {
        returnValue = [];
      }
    } else if (isObject(source)) {
      for (let [key, value] of Object.entries(source)) {
        if (isObject(value) && Reflect.has(returnValue, key)) {
          value = deepMerge(returnValue[key], value);
        }
        returnValue = { ...returnValue, [key]: value };
      }
    }
  }
  return returnValue;
}

export function deepPatch<T extends object>(source: T, update: DeepPartial<T>): T {
  return deepMerge(source, update) as T;
}

/**
 * Type-safe Object.keys for models etc.
 * see https://github.com/microsoft/TypeScript/pull/12253#issuecomment-353494273
 */
export function tsObjectKeys<O>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

/**
 * like [].filter but returns tuple of two objects (first one passes the filter function)
 * @todo improve return type + type key and value?
 */
export function divideObject<T extends {}>(
  obj: T,
  filterFn: <TKey extends keyof T>(key: TKey, val: T[TKey]) => boolean
): [Partial<T>, Partial<T>] {
  let filteredIn: Partial<T> = {};
  let filteredOut: Partial<T> = {};
  for (const key of tsObjectKeys(obj)) {
    const val = obj[key];
    if (filterFn(key, val)) {
      filteredIn[key] = val;
    } else {
      filteredOut[key] = val;
    }
  }
  return [filteredIn, filteredOut];
}
