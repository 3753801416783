import { FlatResourceCache, ResourceCache } from './types';

// @review make sense of default values

/**
 * Creates a "flat" cache from normal one.
 */
export function createFlatResourceCache<TValue>(
  cache: ResourceCache<TValue, null>,
  key: string
): FlatResourceCache<TValue, null> {
  return {
    setValue: (value: TValue) => {
      cache.setValue(key, value);
    },
    getValue: () => {
      return cache.getValue(key, null);
    },
    exists: () => {
      return cache.hasKey(key);
    },
    delete: () => {
      return cache.deleteKey(key);
    },
  };
}

// export function createFlatListResourceCache<T>(
//   cache: ResourceCache<T[], []>,
//   key: string
// ): FlatResourceCache<T[], []> {
//   return {
//     setValue: (value: T[]) => {
//       cache.setValue(key, value);
//     },
//     getValue: () => {
//       return cache.getValue(key, []);
//     },
//     exists: () => {
//       return cache.hasKey(key);
//     },
//     delete: () => {
//       return cache.deleteKey(key);
//     },
//   };
// }
