import { Container } from '@whys/app/lib/state';
import { StaticPageNames, SystemPageNames } from '@whop/niceurls/types';
import { InitialDataModel } from '../app.base/initialData';
import { AppResourceContext } from '../app.types/state';
import { httpResources as niceurlsHttpResources } from '@whop/niceurls';
import { defineSelector } from '../tmp.prototyping/selector';
import { ProjectIdNames } from '@whop/core/types';
import { readCookie, writeCookie } from '@whop/browser/cookies';
import { UserPermissionsNames } from '@whop/user/types';
import { PlainResource } from '@whop/resources/types';
import { definePlainAppNullableResource } from '../tmp.prototyping/appLevelResources';
import { AppLanguageEnum } from '../app.types/app';

type SystemPageModel = InitialDataModel['system_pages'];
type SystemPagePayload = InitialDataModel['system_pages'];
function mapSystemPage(payload: SystemPagePayload): SystemPageModel {
  return payload;
}

type StaticPageModel = InitialDataModel['static_pages'];
type StaticPagePayload = InitialDataModel['static_pages'];
function mapStaticPage(payload: StaticPagePayload): StaticPageModel {
  return payload;
}

type CustomUserPermissionNames = 'xReadCart';

const resources = {
  ...niceurlsHttpResources,
};

type LocalState = void;

type LocalProps = {
  initialData: InitialDataModel;
  resourceContext: AppResourceContext;
  projectId: ProjectIdNames;
  language: AppLanguageEnum;
};

export class GlobalContainer extends Container<LocalState> {
  private state: LocalState;

  systemPages: PlainResource<SystemPageModel, null>;
  staticPages: PlainResource<StaticPageModel, null>;

  select = defineSelector({
    operator: () => this.props.initialData.operator,
    phoneNumber: () => this.props.initialData.operator.primary_phone_number,
    openingHours: () => this.props.initialData.operator.opening_hours,
    claim: () => this.props.initialData.site.claim,
    defaultPageTitle: () => this.props.initialData.site.page_title,
    siteName: () => this.props.initialData.site.name,
  });

  constructor(private props: LocalProps) {
    super();

    const { initialData, resourceContext } = props;

    this.systemPages = definePlainAppNullableResource(resources.systemPages, {
      resourceContext,
      initialValue: initialData.system_pages,
      map: mapSystemPage,
    });

    this.staticPages = definePlainAppNullableResource(resources.staticPages, {
      resourceContext,
      initialValue: initialData.static_pages,
      map: mapStaticPage,
    });
  }

  /**
   * A simple helper because system pages needs only urls.
   */
  systemPageUrl(name: SystemPageNames): string {
    return this.systemPageInfo(name).url;
  }

  systemPageInfo(pageName: SystemPageNames): { name: string; url: string } {
    const infos = this.systemPages.select();
    const pageInfo = infos && infos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '', url: '' };
  }

  staticPageInfo(pageName: StaticPageNames): { name: string; url: string } {
    const infos = this.staticPages.select();
    const pageInfo = infos && infos[pageName];
    if (pageInfo) {
      return pageInfo;
    }
    return { name: '', url: '' };
  }

  allowedTo(...perms: Array<UserPermissionsNames>): boolean {
    const { permissions } = this.props.initialData;
    return perms.map((p) => permissions[p]).every((isAllowed) => isAllowed === true);
  }

  hasCustomPermission(custom: CustomUserPermissionNames) {
    return matchEachToBoolean(custom, {
      xReadCart: () => {
        // @review ... how to handle this
        return this.allowedTo('create_order');
      },
    });
  }

  //
  // Cookie consent
  //

  // Note(cookies_confirmed): privacy policy mentions this key, keep it
  cookieConfirmKey = 'cookies_confirmed';
  cookieConfirmValue = '1';

  acceptCookies() {
    writeCookie(this.cookieConfirmKey, this.cookieConfirmValue, { expireInDays: 365 });
  }

  selectCookiesAccepted(): boolean {
    return readCookie(this.cookieConfirmKey) === this.cookieConfirmValue;
  }

  /**
   * DO NOT USE THIS in production code. Use it only for temporary code.
   * Every custom code should be handled via theme or component registry.
   */
  __isSpecificProject(projectName: ProjectIdNames) {
    return this.selectProjectId() === projectName;
  }

  selectProjectId() {
    return this.props.projectId;
  }

  selectLanguage(): AppLanguageEnum {
    return this.props.language;
  }
}

function matchEachToBoolean<T extends string>(value: T, cases: Record<T, () => boolean>) {
  return cases[value]();
}

export type GlobalContainerType = GlobalContainer;
