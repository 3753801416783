import * as React from 'react';
import { createContext, useContext } from 'react';

import { CustomizationConfig } from '../app.types/app';
import { useProject } from '../app.use/useProject';

const CustomizationContext = createContext<CustomizationConfig>({
  components: {},
});

export function CustomizationProvider(props: { children: React.ReactNode }) {
  const project = useProject();
  return (
    <CustomizationContext.Provider value={project.customize}>
      {props.children}
    </CustomizationContext.Provider>
  );
}

export function useCustomization(): CustomizationConfig {
  const value = useContext(CustomizationContext);
  return value;
}
