import {
  ShippingAddressModel,
  ShippingAddressPayload,
  BusinessOverviewPayload,
  BusinessOverviewModel,
  BillingAddressPayload,
  BillingAddressModel,
  WorkerPayload,
  WorkerModel,
} from './types';
import { coerce } from '@whop/core/mapping';
import { defineHttpResources } from '@whop/containers';

export const httpResources = defineHttpResources({
  // read
  listBusinesses: { url: '/api/business/' },
  showBillingAddress: { url: '/api/profile/address-billing/' },
  listWorkers: { url: '/api/business/worker-roles/' },
  // actions
  deleteShippingAddr: (id: string) => ({
    url: `/api/business/address-shipping/${id}/`,
    method: 'DELETE',
  }),
  deleteBusiness: (id: string) => ({
    url: `/api/business/address-shipping/${id}/`,
    method: 'DELETE',
  }),
  editBusiness: (id: string) => ({
    url: `/api/business/address-shipping/${id}/`,
    method: 'PUT',
  }),
  createShippingAddress: { url: `/api/business/address-shipping/`, method: 'POST' },
  deactivateWorker: (id: string) => ({
    url: `/api/jsonforms/worker-role/${id}/`,
    method: 'DELETE',
  }),
});

export function mapBillingAddress(payload: BillingAddressPayload): BillingAddressModel {
  return {
    id: coerce.str(payload.id),
    business_id: coerce.str(payload.business_id),
    email: payload.email || '',
    company_name: payload.company_name || '',
    first_name: payload.first_name || '',
    last_name: payload.last_name || '',
    street_address: payload.street_address || '',
    zip_code: payload.zip_code || '',
    city: payload.city || '',
    country: payload.country || '',
    mobile_number: payload.mobile_number || '',
    phone_number: payload.phone_number || '',
    fax_number: payload.fax_number || '',
    send_catalog: payload.send_catalog,
    //
    vat_number: payload.vat_number || '',
    identification_number: payload.identification_number || '',
    international_vat_id: payload.international_vat_id || '',
    is_shipping: payload.is_shipping,
    billing_email: coerce.str(payload.billing_email),
  };
}

export function mapShippingAddress(payload: ShippingAddressPayload): ShippingAddressModel {
  return {
    id: coerce.str(payload.id),
    business_id: coerce.str(payload.business_id),
    email: payload.email || '',
    company_name: payload.company_name || '',
    first_name: payload.first_name || '',
    last_name: payload.last_name || '',
    street_address: payload.street_address || '',
    zip_code: payload.zip_code || '',
    city: payload.city || '',
    country: payload.country || '',
    mobile_number: payload.mobile_number || '',
    phone_number: payload.phone_number || '',
    fax_number: payload.fax_number || '',
    send_catalog: payload.send_catalog,
  };
}

export function mapBusinessOverview(payload: BusinessOverviewPayload): BusinessOverviewModel {
  return {
    businesses: payload.businesses.map((payloadBussiness) => {
      return {
        id: String(payloadBussiness.id),
        shippingAddress: mapShippingAddress(payloadBussiness.shipping_address),
        flags: {
          can_edit: payloadBussiness.can_edit,
          can_delete: payloadBussiness.can_delete,
          can_order: payloadBussiness.can_order,
          is_billing: payloadBussiness.is_billing,
        },
        state: payloadBussiness.state,
      };
    }),
  };
}
//could be mapped differently later
export function mapWorker(payload: WorkerPayload): WorkerModel {
  return {
    id: coerce.str(payload.id),
    title: payload.title || '',
    first_name: payload.first_name,
    last_name: payload.last_name,
    phone_number: payload.phone_number || '',
    mobile_number: payload.mobile_number || '',
    datetime_of_birth: coerce.dateOrFallback(payload.datetime_of_birth, null),
    user: payload.user || [],
    worker_roles: payload.worker_roles || [],
  };
}
