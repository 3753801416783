import { AppContainer } from '../app.types/state';
import { mapInitialData, InitialDataModel, defaultInitialData } from '../app.base/initialData';
import { httpResources } from '@whop/core';
import { InitialDataPayload } from '@whop/core/types.initialdata';

export async function createInitialData(self: AppContainer): Promise<InitialDataModel> {
  const {
    __internal: { initialData },
  } = self.context;
  if (initialData) {
    return initialData;
  }
  const result = await self.context.fetchJson<InitialDataPayload>({
    url: httpResources.initialData.url,
  });
  if (result.status === 'ok') {
    return mapInitialData(result.data);
  }
  return defaultInitialData;
}
