import * as React from 'react';
import { createContext, useContext } from 'react';
import { ParamResource } from '@whop/resources/types';
import { PageInfoResult } from '../pkg.pages/types';
import { SystemPageNames, NiceUrlPageModelNames } from '@whop/niceurls/types';

export type PagesContextValue = {
  preloadUrl: (options: { pathname: string; search: string }) => Promise<void>; // copy-pasted to avoid circular imports
  niceUrlInfos: ParamResource<PageInfoResult | null, [string]>;
  selectSystemComponent: (name: SystemPageNames) => React.ComponentType | null;
  selectNiceUrlComponent: (name: NiceUrlPageModelNames) => React.ComponentType | null;
};

const ctx = createContext<PagesContextValue | null>(null);

export function PagesContextProvider(props: {
  children: React.ReactNode;
  value: PagesContextValue;
}) {
  return <ctx.Provider value={props.value}>{props.children}</ctx.Provider>;
}

export function usePagesContext(): PagesContextValue {
  const value = useContext(ctx);
  if (!value) {
    throw new Error('Please use PagesContextProvider.');
  }
  return value;
}
