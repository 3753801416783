import { Container } from '@whys/app/lib/state';
import {
  AppResourceContext,
  defineEntityAppResource,
  EntityResource,
  defineParamAppResource,
} from '../tmp.prototyping/appLevelResources';
import { ProductDetailModel, ProductItemModel } from '@whop/product/types';

// @ts-ignore (UntypedImportForNow)
import { create as createResourceByIdCache } from '@whys/fetch/lib/resources/ResourceByIdCache';
import { mapProduct, httpResources } from '@whop/product';
import { AppCache } from '../app.types/app';
import { FetchEnvType } from '@whop/app/types';
import { ParamResource } from '@whop/resources/types';
import { FirstArgument } from '@whop/utils/types';

type ResourceByIdCache<T, T2> = AnyProtoTypeLater;

type LocalState = void;

type LocalProps = {
  resourceContext: AppResourceContext;
  appCache: AppCache;
  fetchEnv: FetchEnvType;
};

export class ProductContainer extends Container<LocalState> {
  private state: LocalState;

  productDetails: ResourceByIdCache<ProductDetailModel, null>;
  productDetailAlt: EntityResource<ProductDetailModel>;
  relatedProducts: ParamResource<ProductItemModel[], [string]>;
  similarProducts: ParamResource<ProductItemModel[], [string]>;

  constructor(private props: LocalProps) {
    super();

    const { appCache, fetchEnv, resourceContext } = props;

    this.productDetails = createResourceByIdCache({
      fetchEnv,
      cache: appCache.getOrCreateCache('ProductContainer.productDetails'),
      mapData: mapProduct,
      getUrlForId: (id: string) => httpResources.productDetail(id).url,
      getUrlForMultiIds: (ids: string[]) => httpResources.listProductsByIds(ids).url,
      getEmptyResource: () => {
        return null;
      },
    });

    this.productDetailAlt = defineEntityAppResource(httpResources.productDetail, {
      resourceContext,
      map: mapProduct,
    });

    this.relatedProducts = defineParamAppResource<
      ProductItemModel[],
      [string],
      FirstArgument<typeof mapProduct>[]
    >(httpResources.relatedProducts, {
      resourceContext,
      map: (items) => items.map(mapProduct),
      fallback: [],
    });

    this.similarProducts = defineParamAppResource<
      ProductItemModel[],
      [string],
      FirstArgument<typeof mapProduct>[]
    >(httpResources.similarProducts, {
      resourceContext,
      map: (items) => items.map(mapProduct),
      fallback: [],
    });
  }

  async resolveProductsToList(ids: string[]): Promise<Array<ProductDetailModel>> {
    const productsMap = await this.productDetails.getResourcesMap(ids);
    // @review: is it sorted?
    return Array.from(productsMap.values());
  }

  async resolveProductsToMap(ids: string[]): Promise<Map<string, ProductDetailModel>> {
    return await this.productDetails.getResourcesMap(ids);
  }
}

export type ProductContainerType = ProductContainer;
