import { AppContainer } from '../app.types/state';
import { LoginContainer } from '../app.containers/LoginContainer';
import { createInitialData } from './createInitialData';
import { mapProfile } from '@whop/profile/mapping';
// import { LOGIN_SYNC_LS_KEY } from '../app.constants/persist';
// import { localStorageApi } from '../utils.browser/localStorageApi';

export async function createLoginContainer(self: AppContainer): Promise<LoginContainer> {
  const { djreactState } = self.context;
  // const isLogged = localStorageApi.getValue(LOGIN_SYNC_LS_KEY) === true;
  const isLogged = djreactState.logged;
  const initialData = await self.resolveOnce(createInitialData);
  const initialProfile = initialData.profile ? mapProfile(initialData.profile) : undefined;
  return new LoginContainer({
    ...self.context,
    initialProfile,
    initialState: {
      isLogged,
    },
  });
}
