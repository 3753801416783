import { Container } from '@whys/app/lib/state';
import {
  AppResourceContext,
  fetchJsonAsBool,
  definePaginatedAppResource,
} from '../tmp.prototyping/appLevelResources';
import { PaginatedResource } from '@whop/resources/types';
import { httpResources } from '@whop/product';
import { ProductItemModel } from '@whop/product/types';
import { mapProductItem } from '@whop/product/mapping';
import { ConfigContainerType } from './ConfigContainer';

type LocalState = { favoritesCount: number };

type LocalProps = {
  resourceContext: AppResourceContext;
  configContainer: ConfigContainerType;
};

export class FavoritesContainer extends Container<LocalState> {
  private state: LocalState;
  private __tmpFaved: Set<string> = new Set();
  private __tmpUnfaved: Set<string> = new Set();

  favoriteProducts: PaginatedResource<ProductItemModel>;

  constructor(private props: LocalProps) {
    super();

    const { resourceContext, configContainer } = props;

    // @todo read from initial data
    this.state = { favoritesCount: 0 };

    this.favoriteProducts = definePaginatedAppResource(httpResources.listFavoriteProducts, {
      resourceContext,
      mapItem: mapProductItem,
      options: {
        initialPageSize: configContainer.selectInitialPageSize('variant'),
      },
    });
  }

  async setIsFavorite(item: ProductItemModel, isFavorite: boolean) {
    const { id } = item;
    if (isFavorite === true) {
      if (!this.__tmpFaved.has(id)) {
        this.__tmpFaved.add(id);
        this.setState({ favoritesCount: this.state.favoritesCount + 1 });
      }
      this.__tmpUnfaved.delete(id);
      await fetchJsonAsBool(httpResources.favoriteProduct(id), {
        resourceContext: this.props.resourceContext,
      });
      return;
    }

    this.__tmpUnfaved.add(id);
    if (this.__tmpFaved.delete(id)) {
      this.setState({ favoritesCount: this.state.favoritesCount - 1 });
    }
    await fetchJsonAsBool(httpResources.unfavoriteProduct(id), {
      resourceContext: this.props.resourceContext,
    });
  }

  isFavorite(item: ProductItemModel) {
    const { id } = item;
    // Note: the temporary state has precedence over API values
    if (this.__tmpFaved.has(id)) {
      return true;
    }
    if (this.__tmpUnfaved.has(id)) {
      return false;
    }
    return item.is.favorite;
  }
}

export type FavoritesContainerType = FavoritesContainer;
