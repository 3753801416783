import { definePlainResource, PlainResourceProps } from '@whop/resources/plain';
import { HttpResource, HttpResourceCallable } from '@whop/containers';
import { createFlatResourceCache } from '@whop/resources/utils';
import { defineParamResource } from '@whop/resources/param';
import { ResourceFetchError } from '@whop/resources/errors';
import { ParamResource, PlainResource } from '@whop/resources/types';
import { definePaginatedResource } from '@whop/resources/paginated';
import { getPaginationState } from '@whop/pagination';
import { _IS_CLIENT_INTERNAL } from '../app.constants/runtime';
import { IS_PRODUCTION_BUILD } from '../app.constants/env';
import { AppResourceContext } from '../app.types/state';

export { AppResourceContext };

function readFromCacheIdentity<T>(serialized: unknown): T {
  return serialized as T;
}

const __doNotWarnSuspense = _IS_CLIENT_INTERNAL && IS_PRODUCTION_BUILD;

/**
 * @overview Resource integration code with application.
 *
 * Containers should be aware-less of fetching logic. It should just define which resources
 * they'll provide and add custom logic on top of it (if desired).
 *
 * Containers accepts an opaque context prop, which they pass down to function that defines
 * resources. Container never uses the opaque context prop.
 *
 * If container requires more low-level handling, it ignores this integration and uses more low-level API.
 */

export function definePlainAppResource<TModel, TPayload>(
  resource: HttpResource,
  options: {
    resourceContext: AppResourceContext;
    map: (payload: TPayload) => TModel;
    readFromCache?: PlainResourceProps<TModel>['readFromCache'];
    fallback: TModel;
    initialValue?: TModel;
    onChange?: () => void;
  }
) {
  const { resourceContext, map, fallback, onChange } = options;
  const { __fetchJson, __appCache } = resourceContext;

  // const resourceKey = resource.url;

  // should return :
  // - resolved value
  // - cancel
  // - error if failed
  // - key (?)

  const plainCache = __appCache.getOrCreateCache<AnyInternalOnly>('plain');
  const key = resource.url;

  const cache = createFlatResourceCache<TModel>(plainCache, key);

  const resolve = async () => {
    const result = await __fetchJson<TPayload, void, void>(resource);
    if (result.status === 'ok') {
      return map(result.data);
    }
    throw new ResourceFetchError({ resourceName: key, response: result.response });
  };

  return definePlainResource<TModel>({
    name: key, // @review
    resolve,
    cache,
    fallbackValue: fallback,
    initialValue: options.initialValue,
    readFromCache: options.readFromCache || readFromCacheIdentity,
    onChange,
    options: {
      __manageResolve: resourceContext.__manageResolve,
      __isDebug: resourceContext.__isDebug,
      __doNotWarnSuspense,
    },
  });
}

export function definePlainAppNullableResource<TModel, TPayload>(
  resource: HttpResource,
  options: {
    resourceContext: AppResourceContext;
    map: (payload: TPayload) => TModel;
    readFromCache?: PlainResourceProps<TModel>['readFromCache'];
    initialValue?: TModel;
    onChange?: () => void;
    mock?: () => TPayload;
  }
): PlainResource<TModel, null> {
  const { resourceContext, map, initialValue } = options;
  const { __fetchJson, __appCache } = resourceContext;
  const plainCache = __appCache.getOrCreateCache<AnyInternalOnly>('plain');
  const key = resource.url;
  const cache = createFlatResourceCache<TModel>(plainCache, key);

  const resolve = async () => {
    if (options.mock) {
      return map(options.mock());
    }
    const result = await __fetchJson<TPayload, void, void>(resource);
    if (result.status === 'ok') {
      return map(result.data);
    }
    throw new ResourceFetchError({ resourceName: key, response: result.response });
  };

  return definePlainResource<TModel, null>({
    name: key, // @review
    resolve,
    cache,
    readFromCache: options.readFromCache || readFromCacheIdentity,
    fallbackValue: null,
    initialValue,
    onChange: options.onChange,
    options: {
      __doNotWarnSuspense,
      __manageResolve: resourceContext.__manageResolve,
      __isDebug: resourceContext.__isDebug,
    },
  });
}

// @review: should be definePlainListAppResource => how to structure presets?
export function definePlainListResource<TModel, TPayload>(
  resource: HttpResource,
  options: {
    resourceContext: AppResourceContext;
    mapItem: (payload: TPayload) => TModel;
  }
) {
  const { mapItem, resourceContext } = options;
  return definePlainAppResource(resource, {
    resourceContext,
    fallback: [],
    map: (items: TPayload[]) => items.map(mapItem),
  });
}

export function defineParamListResource<TModel, TPayload>(
  resource: HttpResourceCallable,
  options: {
    resourceContext: AppResourceContext;
    mapItem: (payload: TPayload) => TModel;
  }
) {
  const { mapItem, resourceContext } = options;
  return defineParamAppResource(resource, {
    resourceContext,
    fallback: [],
    map: (items: TPayload[]) => items.map(mapItem),
  });
}

export function defineParamAppResource<
  TModel,
  TParams extends Array<AnyGenerics>,
  TPayload,
  TDefault = TModel
>(
  resource: HttpResourceCallable,
  options: {
    resourceContext: AppResourceContext;
    map: (payload: TPayload) => TModel;
    readFromCache?: PlainResourceProps<TModel>['readFromCache'];
    fallback: TDefault;
  }
): ParamResource<TModel, TParams, TDefault> {
  const { resourceContext, map, fallback } = options;
  const { __fetchJson, __appCache } = resourceContext;

  const getKey = (...params: TParams) => {
    return resource(...params).url;
  };

  const resolve = async (...params: TParams) => {
    // const key = getKey(...params);
    const resolvePlain = async () => {
      const result = await __fetchJson<TPayload, void, void>(resource(...params));
      if (result.status === 'ok') {
        return map(result.data);
      }
      const resourceName = resource(...params).url;
      throw new ResourceFetchError({ resourceName, response: result.response });
    };
    return resolvePlain();
  };

  return defineParamResource<TModel, TParams, TDefault>({
    getKey,
    resolve,
    instancesCache: resourceContext.__runtimeCache.getOrCreateCache('param'),
    cache: __appCache,
    readFromCache: options.readFromCache || readFromCacheIdentity,
    defaultValue: fallback,
    options: {
      __manageResolve: resourceContext.__manageResolve,
      __isDebug: resourceContext.__isDebug,
    },
  });
}

// @review: there could be second generics for the fallback type (should be null by default)
export type EntityResource<T> = ParamResource<T, [string], null>;

export function defineEntityAppResource<TModel, TPayload>(
  resource: HttpResourceCallable,
  options: {
    resourceContext: AppResourceContext;
    map: (payload: TPayload) => TModel;
    readFromCache?: PlainResourceProps<TModel>['readFromCache'];
  }
) {
  return defineParamAppResource<TModel, [string], TPayload, null>(resource, {
    fallback: null,
    ...options,
  });
}

/**
 * EntityListResource is a list that depends on an entity (by id), but it can always fallback
 * to an empty list.
 */
export type EntityListResource<T> = ParamResource<T, [string], T>;

export function defineEntityListAppResource<TModel, TPayload>(
  resource: HttpResourceCallable,
  options: {
    resourceContext: AppResourceContext;
    mapItem: (payload: TPayload) => TModel;
  }
) {
  let resourcesContainer = new Map<string, PlainResource<TModel[]>>();
  return (id: string) => {
    const existing = resourcesContainer.get(id);
    if (existing) {
      return existing;
    }
    const inst = definePlainListResource(resource(id), options);
    resourcesContainer.set(id, inst);
    return inst;
  };
}

export async function fetchJsonAsBool(
  resource: HttpResource,
  props: { resourceContext: AppResourceContext }
): Promise<boolean> {
  const { __fetchJson } = props.resourceContext;
  const result = await __fetchJson(resource);
  return result.status === 'ok';
}

export function definePaginatedAppResource<TModel, TPayload>(
  resource: HttpResource,
  options: {
    resourceContext: AppResourceContext;
    mapItem: (payload: TPayload) => TModel;
    readFromCache?: PlainResourceProps<TModel[]>['readFromCache'];
    options: {
      initialPageSize: number;
    };
  }
) {
  const { resourceContext } = options;
  const initialUrl = resource.url;
  const key = initialUrl;
  const cache = resourceContext.__appCache.getOrCreateCache<AnyInternalOnly>(key);
  const { __fetchJson } = resourceContext;

  const resolve = async (params: { url: string }) => {
    const { url } = params;
    const result = await __fetchJson<TPayload[]>({ url });
    if (result.status === 'ok') {
      const paginationState = getPaginationState(result.response);
      const nextPageUrl = paginationState.next;
      return {
        items: result.data.map(options.mapItem),
        nextPageUrl,
      };
    }
    return { items: [], nextPageUrl: '' };
  };

  const config = {
    initialPageSize: options.options.initialPageSize,
  };

  return definePaginatedResource<TModel>({
    cache,
    readFromCache: options.readFromCache || readFromCacheIdentity,
    resolve,
    instancesCache: resourceContext.__runtimeCache.getOrCreateCache('paginated'),
    initialUrl,
    config,
    options: {
      __manageResolve: resourceContext.__manageResolve,
      __isDebug: resourceContext.__isDebug,
    },
  });
}
