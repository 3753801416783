import React from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Trans } from '@lingui/react';
import { RuntimeEnvNames } from '../app.types/enums';
import { AppLogger } from '../app.types/app';

/**
 * Shows info to the developer.
 */
function DevFallback(props: FallbackProps) {
  const { error, componentStack, resetErrorBoundary } = props;
  return (
    <div role="alert">
      <p>
        React <strong>has crashed</strong> in JSX.
      </p>
      <pre>{error?.name}</pre>
      <pre>{error?.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Reload app</button>
    </div>
  );
}

/**
 * Shows info to a user when whole app crashes.
 */
function GlobalProdFallback(props: FallbackProps) {
  return (
    <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div role="alert">
        <h1>
          <Trans>Something went wrong</Trans>
        </h1>
        <button onClick={props.resetErrorBoundary}>
          <Trans>Reload page</Trans>
        </button>
      </div>
    </div>
  );
}

/**
 * Shows info to a user when code of specific page crashes.
 */
function PageProdFallback(props: FallbackProps) {
  return (
    <div role="alert">
      <h1>
        <Trans>Something went wrong</Trans>
      </h1>
      <button onClick={props.resetErrorBoundary}>
        <Trans>Try again</Trans>
      </button>
    </div>
  );
}

function CommonErrorBoundary(props: {
  children: React.ReactNode;
  environment: RuntimeEnvNames;
  logger: AppLogger;
  onReset: () => void;
  fallbackRender: (props: FallbackProps) => React.ReactElement;
}) {
  return (
    <ErrorBoundary
      onError={(e) => {
        props.logger.logError(e);
      }}
      fallbackRender={props.fallbackRender}
      onReset={props.onReset}
    >
      {props.children}
    </ErrorBoundary>
  );
}

type ErrorBoundaryProps = Omit<React.ComponentProps<typeof CommonErrorBoundary>, 'fallbackRender'>;

export function GlobalErrorBoundary(props: ErrorBoundaryProps) {
  const isDevel = props.environment !== 'production';
  return (
    <CommonErrorBoundary
      {...props}
      fallbackRender={(props) => {
        return isDevel ? <DevFallback {...props} /> : <GlobalProdFallback {...props} />;
      }}
    />
  );
}

export function PageErrorBoundary(props: ErrorBoundaryProps) {
  const isDevel = props.environment !== 'production';
  return (
    <CommonErrorBoundary
      {...props}
      fallbackRender={(props) => {
        return isDevel ? <DevFallback {...props} /> : <PageProdFallback {...props} />;
      }}
    />
  );
}
