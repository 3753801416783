import { useTheme, useResponsiveIdx } from './context';
import { SystemPropValues, SystemThemeSpec, ResponsiveValues } from './types';
import { systemImpl } from './system';

const { _resolveSystemProp } = systemImpl;

function coerceCssString(value: number | string): string {
  return typeof value === 'string' ? value : `${value}px`;
}

function castToPx(value: number | string): number {
  if (typeof value === 'number') {
    return value;
  }
  if (value.includes('px')) {
    return Number(value.split('px')[0]);
  }
  // @todo !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  return 0;
}

/**
 * Returns a function that resolves system prop to actual css value
 */
export function useResolveSystemPropFn() {
  const responsiveIdx = useResponsiveIdx();
  const theme = useTheme();
  return <T extends keyof SystemPropValues>(prop: T, value: SystemPropValues[T]) => {
    return _resolveSystemProp(prop, value, responsiveIdx, theme);
  };
}

/**
 * Provides helper to get space value from system theme.
 *
 * !!! Returns string with unit applied.
 *
 * Usage:

  const resolveSpace = useSystemUrlFn()
  return <div
    css={css`
      top: ${resolveSpace([1])};
    `}
  />
 */
export function useResolveSpaceFn() {
  const resolveSystemProp = useResolveSystemPropFn();
  return (
    value: keyof SystemThemeSpec['space'] | ResponsiveValues<keyof SystemThemeSpec['space']>
  ): string => {
    return coerceCssString(resolveSystemProp('space', value instanceof Array ? value : [value]));
  };
}

export function useResolveSpaceToPxFn() {
  const resolveSystemProp = useResolveSystemPropFn();
  return (
    value: keyof SystemThemeSpec['space'] | ResponsiveValues<keyof SystemThemeSpec['space']>
  ): number => {
    return castToPx(resolveSystemProp('space', value instanceof Array ? value : [value]));
  };
}
