import { Container } from '@whys/app/lib/state';
import { FetchJsonFn } from '@whop/containers/types';
import { PlainResource } from '@whop/resources/types';
import { httpResources, mapMenuItem, mapHeaderItem, mapFooterItem } from '@whop/menu';
import { MenuItemModel, HeaderItemModel, FooterItemModel } from '@whop/menu/types';
import { definePlainListResource, AppResourceContext } from '../tmp.prototyping/appLevelResources';

type LocalState = {};

type LocalProps = {
  fetchJson: FetchJsonFn;
  resourceContext: AppResourceContext;
};

class MenuContainer extends Container<LocalState> {
  private state: LocalState;

  listMenu: PlainResource<MenuItemModel[]>;
  listHeader: PlainResource<HeaderItemModel[]>;
  listFooter: PlainResource<FooterItemModel[]>;

  constructor(private props: LocalProps) {
    super();
    this.props = props;
    this.state = {};

    const { resourceContext } = this.props;

    this.listMenu = definePlainListResource(httpResources.listMenu, {
      mapItem: mapMenuItem,
      resourceContext,
    });

    this.listHeader = definePlainListResource(httpResources.listHeader, {
      mapItem: mapHeaderItem,
      resourceContext,
    });

    this.listFooter = definePlainListResource(httpResources.listFooter, {
      mapItem: mapFooterItem,
      resourceContext,
    });
  }
}

export { MenuContainer };
