import Cookies from 'js-cookie';

// from https://docs.djangoproject.com/en/3.0/ref/csrf/#ajax
/**
 * Returns
 * - an empty string on missing cookie value
 */
export function readCookie(name: string): string {
  return Cookies.get(name) || '';
}

export function writeCookie(name: string, value: string, options: { expireInDays: number }) {
  Cookies.set(name, value, {
    expires: options.expireInDays,
  });
}
