import { RuntimeConfig } from '../app.types/app';
import { InitialDataModel } from '../app.base/initialData';

export async function loadRuntimeConfig(options: {
  initialData: InitialDataModel;
}): Promise<RuntimeConfig> {
  const { config } = options.initialData;
  const { pagination, price } = config;
  return {
    pagination: {
      pageSize: pagination.default_page_size,
      pageSizePerResource: pagination.default_page_sizes,
      defaultMode: 'autoloadWithFallback',
    },
    price: {
      locale: price.locale,
      currency: price.currency,
      minimumFractionDigits: price.minimum_fraction_digits,
      maximumFractionDigits: price.maximum_fraction_digits,
    },
  };
}
