import { RuntimeConfig } from '../app.types/app';
import { PreloadableResource } from '../app.types/state';
import { parse } from '@whop/web/querystring';
import { coerceInt } from '@whop/utils/values';

/**
 * Options:
 * - locationSearch query string can start with or without questionmark
 *   e.g. ?page_size=10 or pagesize=10
 */
export function createPreloadResources(options: {
  runtimeConfig: RuntimeConfig;
  locationSearch: string;
}) {
  const { runtimeConfig, locationSearch } = options;
  return async (...resources: PreloadableResource[]): Promise<void> => {
    await Promise.all(
      resources
        .map((resource) => {
          // duck-typing "PaginatedResource"
          // @review Has been used? is it good idea?
          if (typeof resource === 'function') {
            // @todo: add whyshop-aware helper
            const queryParams = parse(locationSearch, { arrayFormat: 'comma' });
            const pageSize = coerceInt(queryParams.items_count, runtimeConfig.pagination.pageSize);
            const resolved = resource({ pageNumber: 1, pageSize });
            return resolved.load();
          }
          if ('load' in resource) {
            return resource.load();
          }
          return null;
        })
        .filter(Boolean)
    );
  };
}
