import { defineHttpResources } from '@whop/containers';
import {
  MenuItemPayload,
  MenuItemModel,
  FooterItemPayload,
  FooterItemModel,
  HeaderItemPayload,
  HeaderItemModel,
} from './types';
import { coerceModel, coerce } from '@whop/core/mapping';

export const httpResources = defineHttpResources({
  listMenu: { url: `/api/navigation/mainmenu/` },
  listHeader: { url: '/api/navigation/header/' },
  listFooter: { url: '/api/navigation/footer/' },
});

export function mapMenuItem(payload: MenuItemPayload): MenuItemModel {
  const id = String(payload.id);
  return {
    id,
    title: payload.name,
    identifier: coerce.str(payload.identifier),
    url: payload?.page_attributes?.url || '',
    mainImages: payload.main_image,
    children: payload.children ? payload.children.map(mapMenuItem) : [],
  };
}

export function mapFooterItem(payload: FooterItemPayload): FooterItemModel {
  return {
    id: String(payload.id),
    title: coerce.str(payload.title),
    identifier: coerce.str(payload.identifier),
    url: coerce.str(payload.url),
    image: coerceModel.img(payload.image),
    type: 'footer',
    children: payload.children.map(mapFooterItem),
  };
}

export function mapHeaderItem(payload: HeaderItemPayload): HeaderItemModel {
  return {
    id: String(payload.id),
    title: coerce.str(payload.title),
    url: coerce.str(payload.url),
    identifier: coerce.str(payload.identifier),
    image: coerceModel.img(payload.image),
    type: 'header',
    children: payload.children.map(mapHeaderItem),
  };
}
