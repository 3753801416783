import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '../app.use/useTheme';
// import { GlobalRegistry } from '../pkg.syncregistry/GlobalRegistry';
import { useGlobalContainer } from '../app.use/useGlobalContainer';
import { useProject } from '../app.use/useProject';

export function AppDocument(props: {}) {
  const globalContainer = useGlobalContainer();
  const project = useProject();
  const theme = useTheme();
  // @review either add custom head in project or theme
  // <GlobalRegistry use="app.head" />
  const HeadComponent = theme.override.globals.Head;
  const headEl = HeadComponent ? <HeadComponent /> : null;

  const faviconPath = project.webpage.faviconPath;
  const googleFontUrl = project.webpage.googleFonts?.fullUrl;
  const defaultTitle = globalContainer.select('defaultPageTitle');
  return (
    <React.Fragment>
      <Helmet>
        <title>{defaultTitle}</title>
      </Helmet>
      {googleFontUrl ? (
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="crossOrigin" />
          <link href={googleFontUrl} rel="stylesheet" />
        </Helmet>
      ) : null}
      {headEl && <Helmet>{headEl}</Helmet>}
      <Helmet>
        <link href={faviconPath} rel="shortcut icon" type="image/png" />
      </Helmet>
    </React.Fragment>
  );
}
