import { AppContainer } from '../app.types/state';
import { CartContainer } from '../app.containers/CartContainer';
import { createInitialData } from './createInitialData';
import { mapCartStats } from '@whop/cart';
import { createProductContainer } from './createProductContainer';

export async function createCartContainer(self: AppContainer): Promise<CartContainer> {
  const productContainer = await self.resolveOnce(createProductContainer);
  const initialData = await self.resolveOnce(createInitialData);
  const { cartStats } = initialData.frontendCustom;
  const initialStats = cartStats ? mapCartStats(cartStats) : undefined;
  return new CartContainer({
    initialStats,
    productContainer,
    ...self.context,
  });
}
