import React from 'react';

import { Provider as UnstatedProvider } from '@whys/app/lib/state';
import { useMemo } from 'react';
import { SystemProvider } from '@whop/system';
import { useTheme } from '../app.use/useTheme';
import { useMatchedUpBreakpointIdx as useResponsiveIdx } from '../app.base/responsive';
import { CustomizationProvider } from '../app.context/customization';
import { DynamicProjectProvider } from '../app.context/dynamicProject';
import { LinguiProvider } from '../app.context/lingui';
import { I18nType } from '../app.types/npm';
import { Provider as NotificationsProvider } from '@whop/notifications';
import { MuiInjectFirst } from '../tmp.solutions/MuiInjectFirst';
import { AppContainer, ResetAppFn } from '../app.types/state';
import { UniversalCodeProvider } from '../app.context/universalCode';
import { FrameworkProvider } from '../app.context/frameworkContext';
import { PagesContextProvider, PagesContextValue } from '../app.context/pagesContext';

export function WithSystemContext(props: { children: React.ReactNode }) {
  const theme = useTheme();
  const systemValue = useMemo(() => {
    return { theme, useResponsiveIdx };
  }, [theme]);
  return <SystemProvider value={systemValue}>{props.children}</SystemProvider>;
}

export function AppContext(props: {
  children: React.ReactNode;
  i18n: I18nType;
  appContainer: AppContainer;
  onResetApp: ResetAppFn;
  pagesContext: PagesContextValue;
}) {
  const { appContainer, pagesContext } = props;
  const { ssrMatchMedia } = appContainer.context;

  const universalCode = useMemo(() => {
    return { ssrMatchMedia };
  }, [ssrMatchMedia]);

  // Note(UnstatedProvider): we do not use inject here, see app.use/useContainer
  return (
    <FrameworkProvider value={props}>
      <MuiInjectFirst>
        <UnstatedProvider>
          <DynamicProjectProvider ssrMatchMedia={ssrMatchMedia}>
            <CustomizationProvider>
              <WithSystemContext>
                <NotificationsProvider autoDismiss autoDismissTimeout={6000}>
                  <UniversalCodeProvider value={universalCode}>
                    <PagesContextProvider value={pagesContext}>
                      <LinguiProvider value={props.i18n}>{props.children}</LinguiProvider>
                    </PagesContextProvider>
                  </UniversalCodeProvider>
                </NotificationsProvider>
              </WithSystemContext>
            </CustomizationProvider>
          </DynamicProjectProvider>
        </UnstatedProvider>
      </MuiInjectFirst>
    </FrameworkProvider>
  );
}
