/**
 * A type helper to verify all values of an enum were tested.
 *
 * This is out implementation of "exhaustiveness checking". We do not throw an error, but rather
 * require a default harmless value.
 * You can use with both if or switch statements.
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking.
 */
export function allValuesHandled<T>(value: never, defaultValue: T): T {
  console.error(`Unexpected value: \`${value}\`.`);
  return defaultValue;
}
