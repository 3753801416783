import {
  SystemThemeSpec,
  CssNumValue,
  SystemPropType,
  SystemPropValues,
  SystemPropResolved,
} from './types';

/**
 * @overview
 * Helpers
 * - helper functions starts with underscore and do not accept object argument so they are as
 *   efficient as possible. They could be inlined if more tests of public API is added.
 *
 */

const config = {
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  fontFamily: 'fonts',
  color: 'colors',
  backgroundColor: 'colors',
  borderBottomColor: 'colors',
  borderColor: 'colors',
  // padding
  padding: 'space',
  paddingTop: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  paddingRight: 'space',
  // margin
  margin: 'space',
  marginTop: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  marginRight: 'space',
  //
  borderRadius: 'space',
  borderTopLeftRadius: 'space',
  borderTopRightRadius: 'space',
  borderBottomRightRadius: 'space',
  borderBottomLeftRadius: 'space',
  // grid
  gridGap: 'space',
};

const systemPropDefaults = {
  space: 0,
  fontSizes: 'initial',
  textFontSizes: 0,
};

function _resolveSystemProps(
  systemProps: SystemPropType,
  theme: SystemThemeSpec,
  responsiveIdx: number
) {
  let overrideProps: { [key: string]: CssNumValue } = {};
  for (const systemKey of Object.keys(systemProps)) {
    const themeKey = config[systemKey];
    if (!themeKey) {
      console.warn(`Invalid system key: \`${systemKey}\`. Ignored.`);
      continue;
    }
    const propValue = systemProps[systemKey];
    overrideProps[systemKey] = _resolveSystemProp(themeKey, propValue, responsiveIdx, theme);
  }
  return overrideProps;
}

function _resolveResponsiveProps(props: object, responsiveIdx: number) {
  let resultProps: { [key: string]: CssNumValue } = {};
  for (const propKey of Object.keys(props)) {
    const propValue = props[propKey];
    if (propValue && Array.isArray(propValue)) {
      const propMaxIdx = propValue.length - 1;
      const propIdx = Math.min(propMaxIdx, responsiveIdx);
      const val = propValue[propIdx];
      if (val) {
        resultProps[propKey] = val;
      }
    } else {
      console.warn(
        `Invalid responsive prop ${propValue}. It must be array, otherwise it makes no sense.`
      );
    }
  }
  return resultProps;
}

function isNegative(val: unknown): val is number {
  if (typeof val !== 'number') {
    return false;
  }
  return val < 0;
}

function _resolveSystemProp<T extends keyof SystemPropValues>(
  themeKey: T,
  propValue: SystemPropValues[T],
  responsiveIdx: number,
  theme: SystemThemeSpec
): SystemPropResolved[T] {
  const propMaxIdx = propValue.length - 1;
  const propIdx = Math.min(propMaxIdx, responsiveIdx);
  const themeIdx = propValue[propIdx];
  if (themeIdx === undefined) {
    return systemPropDefaults[themeKey];
  }
  // Note: the system value can be negative (makes mostly sense for margins).
  if (isNegative(themeIdx)) {
    const val = theme[themeKey][Math.abs(themeIdx)];
    if (val) {
      return val * -1;
    }
    return systemPropDefaults[themeKey];
  }
  // @review(any): ???
  return (theme[themeKey][themeIdx] as AnyProtoTypeLater) || systemPropDefaults[themeKey];
}

export const systemImpl = {
  _resolveSystemProps,
  _resolveResponsiveProps,
  _resolveSystemProp,
};
