import { deepPatch } from '@whop/utils/object';

import { AppTheme, ThemeSpec } from './types';
import defaultTheme from './defaultTheme';
import { DeepPartial } from '@whop/utils/types';

// + props from AppTheme
// type OverrideTheme = ThemeSpec;

function defaultNamedColors(colors: AppTheme['colors']): AppTheme['namedColors'] {
  return {
    buyAction: colors.primary,
    makeOrderAction: colors.primary,
    labelTxt: colors.text,
    labelBg: colors.primary_bg_300,
  };
}

export function createTheme(from: ThemeSpec): AppTheme {
  const patched = deepPatch<AppTheme>(defaultTheme, from);
  patched.namedColors = { ...defaultNamedColors(patched.colors), ...from.namedColors };
  return patched;
}

export function patchTheme(theme: AppTheme, update: DeepPartial<AppTheme>): AppTheme {
  // @ts-ignore ...
  return deepPatch(theme, update);
}

export { defaultTheme };
