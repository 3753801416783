import { defineHttpResources } from '@whop/containers';

export const httpResources = defineHttpResources({
  dashboard: { url: '/api/profile/dashboard/' },
  profileDetail: { url: '/api/profile/' },
  profileUpdate: { url: '/api/profile/', method: 'PUT' },
  userData: { url: '/api/auth/user/' },
  requestPasswordChange: { url: '/api/auth/password/reset/', method: 'POST' },
  checkSession: {
    url: '/api/auth/password/reset/session/check/',
  },
  confirmPasswordChange: {
    url: '/api/auth/password/reset/confirm/',
    method: 'POST',
  },
  //
  userLogout: { url: '/api/auth/logout/', method: 'POST' },
  userLogin: { url: '/api/auth/login/', method: 'POST' },
});
