import { AppProject } from '../app.types/app';
import { InitialDataModel } from '../app.base/initialData';

export async function loadProject(initialData: InitialDataModel): Promise<AppProject> {
  const projectId = initialData.site.project_id;

  // note: only starter has custom loading logic for now
  if (projectId === 'starter') {
    const loadProject = (await import(`../project.starter/project`)).loadProject;
    return await loadProject({ theme: initialData.site.theme_id });
  }

  try {
    return (await import(`../project.${projectId}/project`)).default;
  } catch (e) {
    console.error(e);
    return (await import(`../project.fallback/project`)).default;
  }
}
