// @todo: check how each works add polyfill only if needed
export async function loadSecondaryPolyfills() {
  // @ts-ignore
  if (!window.ResizeObserver) {
    // @ts-ignore
    window.ResizeObserver = await import('resize-observer-polyfill').default;
  }
  if (!window.IntersectionObserver) {
    await import('intersection-observer');
  }

  require('media-match');
  require('whatwg-fetch');
}
