/**
 * runtime -- module of constants resolved during module load time
 */

/**
 * Should be used only in utility code, not in production code!
 * See conventional/utility component BrowserOnly.
 */
export const _IS_SERVER_INTERNAL = typeof window === 'undefined';
export const _IS_CLIENT_INTERNAL = !_IS_SERVER_INTERNAL;
