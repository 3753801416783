import type { NodeOptions } from '@sentry/node';
import type {
  BrowserOptions,
  captureException as _captureException,
  captureMessage as _captureMessage,
} from '@sentry/browser';

// note: the union should make sure we use only API available on both node/browser
type CommonOptions = NodeOptions | BrowserOptions;

export function createSentryLogger(
  sentryInstance: {
    init: (options: CommonOptions) => void;
    captureException: typeof _captureException;
    captureMessage: typeof _captureMessage;
  },
  options: { environment: 'production' | 'test' | 'development'; dsn: string; release: string }
) {
  const { dsn, environment, release } = options;

  // do not report any error on development even if run in "production" environment
  // const ignoreUrls = ['localhost', '127.0.0.1'];
  const debug = environment !== 'production';

  if (environment === 'production') {
    sentryInstance.init({ dsn, environment, debug, release });
  }

  return {
    logError: (e: Error) => {
      sentryInstance.captureException(e);
    },
    logMessage: (msg: string) => {
      sentryInstance.captureMessage(msg);
    },
  };
}
