import * as materialUI from '@whys/app-plugin-material-ui-4';
import * as gtm from '@whop/app-plugin-gtm';
import * as emotion from './app-plugin-emotion-10';
import * as noie from './app-plugin-noie';
import { AppPluginContext } from '../app.types/state';

const plugins = {
  materialUI: materialUI.setup({ theme: {} }),
  gtm: gtm.setup({
    resolvePluginContext: (appPluginCtx: AppPluginContext) => {
      const gtmId = appPluginCtx.gtm.gtmId || '';
      return {
        gtmId,
      };
    },
  }),
  emotion: emotion.setup(),
  noie: noie.setup(),
};

export default plugins;
