import { useEffect, useMemo, useRef, useState, useLayoutEffect } from 'react';

type UnmountCbFn = () => void;

const noDependenciesIntentionally: unknown[] = [];

export function useDidMount(fn: () => void | UnmountCbFn) {
  return useEffect(fn, noDependenciesIntentionally); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useHasMounted() {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);
  return didMount;
}

export function useUnmount(fn: () => void) {
  return useEffect(() => {
    return fn;
  }, noDependenciesIntentionally); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useRenderOnce(fn: () => JSX.Element | null) {
  return useMemo(fn, noDependenciesIntentionally); // eslint-disable-line react-hooks/exhaustive-deps
}

// from https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  // return previous value (happens before update in useEffect above)
  return ref.current;
}

/**
 * @deprecated not tested
 */
export function useDidValueChange<T>(
  value: T,
  opts?: { expireIn?: number; active?: boolean }
): boolean {
  const [lastValue, setLastValue] = useState(value);

  // Options:
  // - expireIn: It's reset to "not changed" when expireIn elapse, defaults 0.
  // - active: Let you ignore changes under some condition, defaults true.
  const expireIn = (opts && opts.expireIn) || 0;
  const optsActive = opts && opts.active;
  const active = optsActive == null ? true : optsActive;

  useEffect(() => {
    if (!active) {
      return;
    }
    const timer = setTimeout(() => {
      setLastValue(value);
    }, expireIn);
    return () => {
      clearTimeout(timer);
    };
  }, [value, active, expireIn]);

  return active && value !== lastValue;
}

export function useEffectOnChange<T>(value: T, fn: () => void) {
  const ref = useRef(value);
  useEffect(() => {
    if (ref.current !== value) {
      // Note: we don't need to pass it, because the value will be up-to-date in scope
      fn();
    }
    // update
    ref.current = value;
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * An explicit/conventional helper to manifest that I want to manually control dependencies
 * without eslint linting/fixing with the eslint plugin (https://reactjs.org/docs/hooks-rules.html#eslint-plugin)
 */
export function useManualEffect(fn: () => void, dependencies: React.DependencyList) {
  return useEffect(fn, dependencies);
}

export function useForceUpdate() {
  const [counter, setCounter] = useState(0);
  return () => {
    setCounter(counter + 1);
  };
}

/**
 * @see https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/Text.js
 * @review unsure if should be used
 */
export const useUniversalLayoutEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect;
