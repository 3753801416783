// import { useHeaderHeight } from '../app.use/useHeaderHeight';
import { useResolveSpaceToPxFn } from '@whop/system/hooks';

type RuntimeDimensions = {
  headerHeight: number;
  footerHeight: number;
  verticalPadding: number;
  breadcrumbsHeight: number;
};

function useRuntimeDimensions(): RuntimeDimensions {
  const resolveToPx = useResolveSpaceToPxFn();
  // const headerHeight = useHeaderHeight();
  // NotePrototype: this hooks is source of truth for the dimensions, it will probably
  // need something like https://github.com/Swizec/useDimensions
  const spaceUnit = resolveToPx([1, 2, 3]);
  return {
    headerHeight: 151,
    footerHeight: 48,
    verticalPadding: spaceUnit * 2, // Note(* 2) top + bottom
    breadcrumbsHeight: 23 + spaceUnit * 2,
  };
}

export function useAllocatedHeight(): number {
  const dims = useRuntimeDimensions();
  return dims.headerHeight + dims.footerHeight + dims.verticalPadding + dims.breadcrumbsHeight;
}
