import { AppContainer } from '../app.types/state';
import { GlobalContainer } from '../app.containers/GlobalContainer';
import { createInitialData } from './createInitialData';

export async function createGlobalContainer(self: AppContainer): Promise<GlobalContainer> {
  const initialData = await self.resolveOnce(createInitialData);
  return new GlobalContainer({
    ...self.context,
    initialData,
  });
}
