import React from 'react';

import { useStarterContainer } from '../app.use/useStarterContainer';
import { ProjectProvider } from './project';
import { ThemeProvider } from './theme';
import { SsrMatchMediaFn } from '../app.types/state';

export function DynamicProjectProvider(props: {
  children: React.ReactNode;
  ssrMatchMedia?: SsrMatchMediaFn;
}) {
  const starterContainer = useStarterContainer();
  const project = starterContainer.selectProject();
  const theme = project.theme;
  return (
    <ProjectProvider value={project}>
      <ThemeProvider ssrMatchMedia={props.ssrMatchMedia} value={theme}>
        {props.children}
      </ThemeProvider>
    </ProjectProvider>
  );
}
