import { useTheme } from './/useTheme';
import { AppThemeColors } from '../pkg.theme/types';

/**
 * @deprecated use useColorsFn instead (this is not type safe when using like: colors['primary_bg_300'])
 */
export function useColors(): AppThemeColors {
  const theme = useTheme();
  return theme.colors;
}

/**
 * This is type-safe version of useColors
 */
export function useColorsFn() {
  const { colors } = useTheme();
  return (colorName: keyof AppThemeColors) => {
    return colors[colorName];
  };
}
