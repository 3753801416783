import { useAppContainer } from './framework';
import { AppContainer } from '../app.types/state';
import { useEffect, useState, useRef } from 'react';

// interface UnstatedContainer<T extends {} = {}> {
//   state: T;
//   setState(update: T | Partial<T>): Promise<void>;
//   subscribe: (fn: Function) => void;
//   unsubscribe: (fn: Function) => void;
// }

type StateCreator<T> = (self: AppContainer) => T | Promise<T>;

export function useContainer<T>(
  creatorFn: StateCreator<T>,
  __options?: { mode?: 'suspense' | 'lazy' }
): T {
  const { mode = 'suspense' } = __options || {};

  const appContainer = useAppContainer();
  const containerRef = useRef<AnyHowtoType>(appContainer.resolveOnce.fromCache(creatorFn));
  const [_, setContainerState] = useState(containerRef.current?.state);
  const containerInst = containerRef.current;

  async function load() {
    const { promiseManage } = appContainer.context;
    const resolved = await promiseManage.manageResolve(() => appContainer.resolveOnce(creatorFn), {
      key: creatorFn.name,
      canReusePromises: true,
    });
    containerRef.current = resolved;
    return resolved;
  }

  useEffect(() => {
    if (!containerInst) {
      return;
    }
    let isActive = true;
    const onUpdate = () => {
      if (isActive) {
        setContainerState(containerInst.state);
      }
    };
    containerInst.subscribe(onUpdate);
    return () => {
      isActive = false;
      containerInst.unsubscribe(onUpdate);
    };
  }, [containerInst]);

  useEffect(() => {
    if (mode === 'lazy') {
      load().then((inst) => {
        setContainerState((inst as AnyHowtoType).state);
      });
    }
  }, []);

  if (containerInst) {
    return containerInst;
  } else {
    console.error(
      `error(state): Reading state "${creatorFn.name}" that hasn't been resolved yet. This is noop for SSR.`
    );
    throw load();
  }
}

export function useContainerLazy<T>(creatorFn: StateCreator<T>): T | undefined {
  return useContainer(creatorFn, { mode: 'lazy' });
}
