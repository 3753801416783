import { Container } from '@whys/app/lib/state';
import {
  AppResourceContext,
  definePlainAppNullableResource,
} from '../tmp.prototyping/appLevelResources';
import { ProductContainerType } from './ProductContainer';
import { PlainResource } from '@whop/resources/types';
import { CartItemViewModel } from '../app.tsx.types/cart';
import { CartDetailModel, CartStatsModel } from '@whop/cart/types';
import { httpResources, mapCartDetail } from '@whop/cart';
import { definePlainResource } from '@whop/resources/plain';
import { createFlatResourceCache } from '@whop/resources/utils';
import {
  AsyncTaskCreator,
  AsyncParamTaskCreator,
  defineParamTaskFromResource,
  definePlainTaskFromResource,
  CustomParamTaskCreator,
  CustomTaskCreator,
} from '../tmp.prototyping/asyncTasks';
import { models } from '@whop/core/types';
import { defineSelector } from '../tmp.prototyping/selector';
import { doFetch } from '@whys/fetch/lib/fetch';
import { definePlainDerivedResource } from '../tmp.prototyping/derivedResources';
import { ShippingPriceViewModel } from '../pkg.cart/types';
import { CommonError } from '@whop/backend/types';

const emptyStats = {
  total: 0,
  itemsCount: 0,
};

type LocalState = { forceUpdateInc: number; statsStatus: 'idle' | 'loading' };
type LocalProps = {
  productContainer: ProductContainerType;
  resourceContext: AppResourceContext;
  initialStats?: CartStatsModel;
};

type WithCommonUserErrorPayload<T extends {}> = T & {
  error: null | undefined | CommonError;
};

type UpdateItemAsyncTask = {
  run: () => Promise<
    | { ok: true; status: 'ok' }
    | { ok: false; status: 'error'; errorMessage: string; errorKey: string }
    | { ok: false; status: 'crash' }
  >;
};

type BulkImportErrorPayload = WithCommonUserErrorPayload<{}>[] | undefined;
type BulkImportAsyncTask = {
  run: () => Promise<{ ok: boolean; errors: BulkImportErrorPayload }>;
};

type AddItemAsyncTask = UpdateItemAsyncTask; // its same for now

export class CartContainer extends Container<LocalState> {
  private state: LocalState = { forceUpdateInc: 0, statsStatus: 'idle' };

  // resources
  cartDetail: PlainResource<CartDetailModel, null>;
  cartItems: PlainResource<CartItemViewModel[]>;
  cartStats: PlainResource<CartStatsModel>;

  // tasks
  addItemAlt: CustomTaskCreator<{ variant: string; quantity: number }, AddItemAsyncTask>;
  clearItems: AsyncTaskCreator<{}>;
  setItemQuantityAlt: CustomParamTaskCreator<[string], { quantity: number }, UpdateItemAsyncTask>;
  removeItem: AsyncParamTaskCreator<[models.FrontendId], {}>;
  bulkImport: CustomTaskCreator<File[], BulkImportAsyncTask>;

  // selectors
  select = defineSelector({
    // total: () => {
    //   const cartDetail = this.cartDetail.select();
    //   return cartDetail?.prices.total_discount_price_gross ?? 0;
    // },
    // itemsCount: () => {
    //   const cartDetail = this.cartDetail.select();
    //   if (cartDetail) {
    //     return cartDetail.items.length;
    //   }
    //   return 0;
    // },
    status: () => this.state.statsStatus,
  });

  constructor(private props: LocalProps) {
    super();

    const { resourceContext, productContainer } = props;

    //
    // Resources
    //

    this.cartDetail = definePlainAppNullableResource(httpResources.cartDetail, {
      resourceContext,
      map: mapCartDetail,
    });

    this.cartStats = definePlainDerivedResource<CartStatsModel, CartDetailModel>({
      key: 'cartStats',
      fallback: emptyStats,
      resourceContext,
      derivesFrom: this.cartDetail,
      mapTo: (cartDetail) => {
        return {
          total:
            cartDetail.prices.rounded_total_discount_price_gross?.rounded_price ||
            cartDetail.prices.total_discount_price_gross ||
            0,
          itemsCount: cartDetail.itemsCount,
        };
      },
    });

    this.cartItems = (() => {
      const key = 'cart.items';
      const plainCache = resourceContext.__appCache.getOrCreateCache<AnyInternalOnly>(key);
      const cache = createFlatResourceCache(plainCache, key);
      return definePlainResource({
        cache,
        name: key,
        dependencies: [this.cartDetail],
        fallbackValue: [],
        resolve: async () => {
          const detailResource = await this.cartDetail.getOrFetch();
          if (!detailResource) {
            return [];
          }
          const ids = detailResource.items.map((_) => _.variantId);
          const productsById = await productContainer.resolveProductsToMap(ids);
          return detailResource.items
            .map((item) => {
              const product = productsById.get(item.variantId);
              if (!product) {
                return null;
              }
              return { ...item, product };
            })
            .filter(Boolean);
        },
        options: resourceContext,
      });
    })();

    //
    // Tasks
    //

    this.clearItems = definePlainTaskFromResource<{}>(httpResources.clearCart, {
      taskId: 'clearItems',
      resourceContext: this.props.resourceContext,
      __runBefore: async () => {
        this.setState({
          statsStatus: 'loading',
        });
      },
      __runAfter: async () => {
        await this.refetchFullCart();
        this.setState({ statsStatus: 'idle' });
      },
    });

    this.addItemAlt = (data: { variant: string; quantity: number }) => {
      return {
        run: async () => {
          this.setState({
            statsStatus: 'loading',
          });

          type NoResponsePayload = {};
          type ResponsePayload = WithCommonUserErrorPayload<{}>;
          type RequestPayload = { variant: string; quantity: number };

          const result = await resourceContext.__fetchJson<
            NoResponsePayload,
            ResponsePayload,
            RequestPayload
          >(httpResources.addItem, data);

          await this.refetchFullCart();
          this.setState({ statsStatus: 'idle' });

          if (result.status === 'ok') {
            return { ok: true, status: 'ok' };
          }
          if (result.status === 'error') {
            const { data } = result;
            return {
              ok: false,
              status: 'error',
              errorMessage: data?.error?.user_message || '',
              errorKey: data?.error?.code || '',
            };
          }
          return { ok: false, status: 'crash' };
        },
      };
    };

    this.setItemQuantityAlt = (id: models.FrontendId) => {
      return (data: { quantity: number }) => {
        return {
          run: async () => {
            this.setState({
              statsStatus: 'loading',
            });

            type NoResponsePayload = {};
            type ResponsePayload = WithCommonUserErrorPayload<{}>;
            type RequestPayload = { quantity: number };

            const result = await resourceContext.__fetchJson<
              NoResponsePayload,
              ResponsePayload,
              RequestPayload
            >(httpResources.setItemQuantity(id), data);

            await this.refetchFullCart();
            this.setState({ statsStatus: 'idle' });

            if (result.status === 'ok') {
              return { ok: true, status: 'ok' };
            }
            if (result.status === 'error') {
              const { data } = result;
              return {
                ok: false,
                status: 'error',
                errorMessage: data?.error?.user_message || '',
                errorKey: data?.error?.code || '',
              };
            }
            return { ok: false, status: 'crash' };
          },
        };
      };
    };

    this.removeItem = defineParamTaskFromResource<[string], {}>(httpResources.removeItem, {
      resourceContext: this.props.resourceContext,
      getTaskId: (id: models.FrontendId) => `removeItem(variantId=${id})`,
      __runBefore: async () => {
        this.setState({
          statsStatus: 'loading',
        });
      },
      __runAfter: async () => {
        await this.refetchFullCart();
        this.setState({ statsStatus: 'idle' });
      },
    });

    this.bulkImport = (source: File[]) => {
      return {
        getTaskId: () => 'bulkImport',
        run: async () => {
          const { didOk, errors } = await this._doBulkImport(source);
          await this.refetchFullCart();
          return { ok: didOk, errors: errors };
        },
      };
    };
  }

  async refetchFullCart() {
    this.setState({ statsStatus: 'loading' });
    await this.cartDetail.reload();
    this.setState({ statsStatus: 'idle' });
  }

  async _doBulkImport(files: File[]) {
    let data = new FormData();
    for (const file of files) {
      data.append('files', file, file.name);
    }
    const { __fetchEnv } = this.props.resourceContext;
    const { url, method } = httpResources.bulkImport;

    const response = await doFetch(url, __fetchEnv, {
      method,
      body: data,
    });

    if (response?.status === 500) {
      return { didOk: false };
    }

    let jsonErrors;
    if (response) {
      jsonErrors = await response.json();
    }

    return { didOk: !!(response && response.ok), errors: jsonErrors };
  }

  isCartPending() {
    return this.state.statsStatus === 'loading';
  }

  selectAmountInCart(options: { variantId: string }): number | undefined {
    const items = this.cartItems.select();
    const itemInCart = items.find((value) => value.product.id === options.variantId);
    if (itemInCart) {
      return itemInCart.quantity;
    }
    return undefined;
  }

  async getCartItemsByIds(ids: string[]): Promise<CartItemViewModel[]> {
    const byIdsFilter = () => {
      let filterIds = new Set(ids);
      return (item: CartItemViewModel) => {
        return filterIds.has(item.variantId);
      };
    };
    const items = await this.cartItems.getOrFetch();
    return items.filter(byIdsFilter());
  }

  _forceUpdate() {
    this.setState({ forceUpdateInc: this.state.forceUpdateInc + 1 });
  }

  calculateVatPrice(item: CartItemViewModel): number | undefined {
    const vat =
      item.prices.total_discount_price_gross && item.prices.total_discount_price_net
        ? item.prices.total_discount_price_gross - item.prices.total_discount_price_net
        : undefined;
    return vat;
  }

  selectShippingPrice(): ShippingPriceViewModel {
    const detail = this.cartDetail.select();
    if (!detail) {
      return { type: 'priced', priceFrom: 0 };
    }
    const { freeShippingType } = detail;
    if (freeShippingType === 'never_paid') {
      return {
        type: 'free',
      };
    }
    if (freeShippingType === 'free_from' && detail.freeShippingRemaining === 0) {
      return {
        type: 'free',
      };
    }
    // implicitly freeShippingType === 'always_paid'
    return { type: 'priced', priceFrom: detail.shippingPriceFrom };
  }

  selectShippingIsFree() {
    return this.selectShippingPrice().type === 'free';
  }
}
