import { createElement } from 'react';
import { css as propsToClassName } from 'emotion';

import { useTheme, useResponsiveIdx } from './context';
import { SystemPropType, SystemBoxProps, SystemBoxAs } from './types';
import { systemImpl } from './system';

const { _resolveSystemProps, _resolveResponsiveProps } = systemImpl;

// Temporary helper to force non-responsive values to array which the system
// implementation expects.
// With this SystemBox system prop doesn't require array wrapping on css attributes
// where it doesn't make sense.
/**
 * @todo make it automatic (e.g. for every color prop) using config from systemImpl
 */
function _coerceSomePropsToArray(system: SystemPropType): AnyProtoTypeLater {
  let res = { ...system };
  if (system.fontWeight && !Array.isArray(system.fontWeight)) {
    res['fontWeight'] = [system.fontWeight];
  }
  if (system.color && !Array.isArray(system.color)) {
    res['color'] = [system.color];
  }
  if (system.borderBottomColor && !Array.isArray(system.borderBottomColor)) {
    res['borderBottomColor'] = [system.borderBottomColor];
  }
  if (system.borderColor && !Array.isArray(system.borderColor)) {
    res['borderColor'] = [system.borderColor];
  }
  if (system.backgroundColor && !Array.isArray(system.backgroundColor)) {
    res['backgroundColor'] = [system.backgroundColor];
  }
  if (system.fontFamily && !Array.isArray(system.fontFamily)) {
    res['fontFamily'] = [system.fontFamily];
  }
  return res;
}

//
// ⬇ System props shortcuts ⬇
//

/**
 * Inspired by https://styled-system.com/#padding-props, designed for readability.
 */

const preconfig = {
  // @see LocalImplOnlyTwoValues: it expects the value is two item tuple (if its array)

  // @review: for now we define only shortcuts that expands to multiple css attributes which is very handy.
  // For else using typescript completion should help.
  pX: ['paddingLeft', 'paddingRight'],
  pY: ['paddingTop', 'paddingBottom'],
  mX: ['marginLeft', 'marginRight'],
  mY: ['marginTop', 'marginBottom'],
};

const preconfigKeys = new Set(Object.keys(preconfig));

function _expandShortcuts(system: SystemPropType): AnyProtoTypeLater {
  let res = {};
  for (const [key, val] of Object.entries(system)) {
    if (preconfigKeys.has(key)) {
      const expanded = preconfig[key];
      // see LocalImplOnlyTwoValues
      res[expanded[0]] = val;
      res[expanded[1]] = val;
    } else {
      res[key] = val;
    }
  }
  return res;
}

//
// ⬆ System props shortcuts ⬆
//

/**
 * @implementation of SystemBox
 * - not using forwardRef, because it breaks types (add custom a prop e.g. innerRef if needed)
 * - every "as" component should accept className, is it worth it to type it?
 */

/**
 * Wraps any component (by default div) that accepts className prop.
 * The responsive prop accepts key (css attribute) value (array of values represented by responsive breakpoints) mapping.
 * The system prop is like reponsive but the values represents actual values in theme.
 */
export function SystemBox<T extends SystemBoxAs = 'div'>(props: SystemBoxProps<T>) {
  const {
    as = 'div',
    system,
    responsive,
    passRef,
    className,
    style,
    css: cssProp,
    children,
    ...componentProps
  } = props;
  //
  const theme = useTheme();
  const responsiveIdx = useResponsiveIdx();
  const systemCssProps = system
    ? _resolveSystemProps(_coerceSomePropsToArray(_expandShortcuts(system)), theme, responsiveIdx)
    : null;
  const responsiveCssProps = responsive ? _resolveResponsiveProps(responsive, responsiveIdx) : null;

  const finalClassName = `${(propsToClassName as AnyProtoTypeLater)([
    cssProp,
    responsiveCssProps,
    systemCssProps,
  ])}${className ? ` ${className}` : ''}`;
  //
  // Note: precedence is css prop => responsive prop => system prop
  return createElement(
    as,
    {
      className: finalClassName,
      style,
      ...componentProps,
      ref: passRef,
    },
    children
  );
}

// @review: experimental
SystemBox.enhance = (
  displayName: string,
  system: SystemBoxProps<AnyProtoTypeLater>['system'],
  defaultProps?: SystemBoxProps<AnyProtoTypeLater>
) => {
  const hoc = (props: AnyProtoTypeLater) => {
    return createElement(
      SystemBox,
      { ...defaultProps, ...props, system: { ...system, ...props.system } },
      props.children
    );
  };
  // @review: add hoc utilities
  hoc.displayName = displayName;
  return hoc;
};

// export const SystemBoxWithRef = forwardRef((props: SystemBoxProps<AnyHowtoType>, ref) => {
//   return createElement(SystemBox, { ...props, passRef: ref, ref }, props.children);
// });

// @review: move elsewhere, put here for convenience
// @todo: consolidate with SystemBox maybe?
/**
 * Creates className from "system" props.
 */
// function useSystemToClassName(systemProps: SystemPropType): string {
//   return useSystemFn()(systemProps);
// }
// useSystemToClassName.fn = useSystemFn;

/**
 * Usage:
 *
 *   const systemToClassName = useSystem.toClassName();
 */
export const useSystem = {
  // Note: this is one of (possible) more hooks API to get className from system.
  //   - there could be also one that can fully replace SystemBox (system, responsive, css "prop")
  toClassName: useSystemFn,
};

function useSystemFn() {
  const theme = useTheme();
  const responsiveIdx = useResponsiveIdx();
  return (systemProps: SystemPropType) => {
    const systemCssProps = systemProps
      ? _resolveSystemProps(
          _coerceSomePropsToArray(_expandShortcuts(systemProps)),
          theme,
          responsiveIdx
        )
      : null;
    return propsToClassName(systemCssProps);
  };
}

type UseSystemBoxOptions = {
  responsive?: SystemBoxProps['responsive'];
  system?: SystemBoxProps['system'];
  css?: SystemBoxProps['css'];
};

function useSystemBoxFn() {
  const theme = useTheme();
  const responsiveIdx = useResponsiveIdx();

  // @todo: consolidate with SystemBox on any change here

  return (options: UseSystemBoxOptions) => {
    const { css: cssProp, responsive, system } = options;
    const systemCssProps = system
      ? _resolveSystemProps(_coerceSomePropsToArray(_expandShortcuts(system)), theme, responsiveIdx)
      : null;
    const responsiveCssProps = responsive
      ? _resolveResponsiveProps(responsive, responsiveIdx)
      : null;
    return `${(propsToClassName as AnyProtoTypeLater)([
      cssProp,
      responsiveCssProps,
      systemCssProps,
    ])}`;
  };
}

/**
 * A hook version of SystemBox component.
 */
export function useSystemBox(options: UseSystemBoxOptions) {
  return useSystemBoxFn()(options);
}
useSystemBox.fn = useSystemBoxFn;
