import { ToastProvider as Provider, useToasts } from 'react-toast-notifications';
import { useMemo } from 'react';
import React from 'react';

type ToastTypes = 'ok' | 'err' | 'warn' | 'info';
type AddMessageOptions = { duration: 'short' | 'default' | 'long' };

export function useNotifications() {
  const { addToast } = useToasts();
  return useMemo(() => {
    return {
      notify: (type: ToastTypes, msg: React.ReactNode, opts?: AddMessageOptions) => {
        const resolvedOpts = {
          duration: 'default',
          ...opts,
        };

        // NotePrototype: add this config to provider
        const dismissTimeout = {
          short: 2400,
          default: 4500,
          long: 6000,
        }[resolvedOpts.duration];

        const appearance =
          {
            ok: 'success',
            err: 'error',
            info: 'info',
            warn: 'warning',
          }[type] || 'info';

        addToast(msg, {
          appearance,
          autoDismissTimeout: dismissTimeout,
        });
      },
    };
  }, [addToast]);
}

export { Provider };
