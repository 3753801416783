import { isDateValid } from './dates';

export function coerceNum(val: unknown, defaultVal: number): number {
  const asNumber = Number(val);
  if (Number.isNaN(asNumber)) {
    return defaultVal;
  }
  return asNumber * 1;
}

export function coerceInt(val: unknown, defaultVal: number): number {
  if (val === '') {
    return defaultVal;
  }
  const valAsNum = (() => {
    const asNumber = Number(val);
    if (Number.isNaN(asNumber)) {
      return defaultVal;
    }
    return asNumber;
  })();
  return Number(valAsNum.toFixed(0));
}

export function coerceStr(val: unknown, defaultVal: string = ''): string {
  if (typeof val === 'string') {
    return val;
  }
  if (typeof val === 'number') {
    return String(val);
  }
  return defaultVal;
}

/**
 * Returns date if exists and is valid, otherwise fallback to other value.
 */
export function coerceDateOrFallback<T>(val: unknown, defaultVal: T): Date | T {
  if (val instanceof Date) {
    return val;
  }
  if (typeof val === 'string') {
    const asDate = new Date(val);
    if (isDateValid(asDate)) {
      return asDate;
    }
  }
  return defaultVal;
}

export function coerceDate(val: unknown, defaultVal: Date): Date {
  return coerceDateOrFallback(val, defaultVal);
}

export function coerceDateOrToday(val: unknown): Date {
  return coerceDateOrFallback(val, new Date());
}
